import { ErrorBoundary } from 'react-error-boundary';
import { twMerge } from 'tailwind-merge';

import { useT } from '-/i18n';
import Text from '../Text/Text';

import type { PropsWithChildren, ReactNode } from 'react';

export const getCardParentClasses = (className = '') =>
  twMerge('group focus:outline-none', className);

export interface CardProps
  extends PropsWithChildren,
    React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  childrenClassName?: string;
  grandChildrenClassName?: string;
  footerClassName?: string;
  imgAlt?: string;
  imgSrc?: string;
  imgClassName?: string;
  footer?: ReactNode;
}
export const cardBaseStyles =
  'flex flex-col p-5 border bg-white group-hover:shadow-lg group-focus:border-green-900 group-focus:ring-green-900 group-focus:ring-1 transition justify-between rounded';

export const Card = ({
  children,
  className,
  childrenClassName,
  grandChildrenClassName,
  footerClassName,
  imgAlt,
  imgSrc,
  imgClassName,
  footer,
  ...props
}: CardProps) => {
  const t = useT('translation', 'error');
  return (
    <div
      className={twMerge(cardBaseStyles, className)}
      data-testid="aslan-card"
      {...props}
    >
      <ErrorBoundary
        fallback={
          <Text as="span" className="text-red-800">
            {t('generic')}
          </Text>
        }
      >
        <div
          data-testid="aslan-card-children"
          className={twMerge('flex justify-between gap-2', childrenClassName)}
        >
          <div
            className={twMerge(
              'flex flex-col justify-center content-start max-w-full w-full',
              grandChildrenClassName
            )}
          >
            {children}
          </div>
          {imgSrc && (
            <div className="max-w-20 w-full h-20">
              <img
                className={twMerge('object-cover w-full h-full', imgClassName)}
                alt={imgAlt ?? ''}
                src={imgSrc}
              />
            </div>
          )}
        </div>
        {footer && (
          <div
            data-testid="aslan-card-footer"
            className={twMerge('mt-4', footerClassName)}
          >
            {footer}
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
};
export default Card;
